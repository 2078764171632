<template>
  <Container theme="light">
    <!-- step 1 -->
    <template v-if="index === '1'">
      <div class="w_100">
        <div class="d_flex">
          <div class="icon icon_medium">
            <img src="../../../assets/images/public/ic_security_center_cell_phone.webp" alt="" />
          </div>
          <div class="icon">
            <img src="../../../assets/images/public/ic_security_center_arrow.webp" alt="" />
          </div>
          <div class="icon icon_medium">
            <img src="../../../assets/images/public/ic_security_center_captcha.webp" alt="" />
          </div>
        </div>
        <div class="d_flex">
          <p>
            首先，您需要在您的手机上安装一個谷歌验证器(Google Authenticator)
          </p>
        </div>
        <div class="d_flex">
          <a @click.stop.prevent="copydownloadUrl" href="javacreipt:;" class="text_light">
            [复制下载地址]
          </a>
          <input type="hidden" id="download-code" :value="downloadUrl" />
        </div>
        <div class="d_flex justify_content_between download">
          <div>谷歌验证码(IOS)</div>
          <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" ref="download" class="download_button">
            下载
          </a>
        </div>
        <div class="d_flex justify_content_between download">
          <div>谷歌验证码(安卓版)</div>
          <a href="http://dgxdjx.com/admin/googleauthenticator/1605799574491.apk" ref="download" class="download_button">
            下载
          </a>
        </div>
      </div>
    </template>

    <!-- step 2 -->
    <template v-if="index === '2'">
      <div class="w_100">
        <div class="d_flex">
          <div class="icon icon_medium">
            <img src="../../../assets/images/public/ic_security_center_key.webp" alt="" />
          </div>
        </div>
        <div class="d_flex">
          <p>
            请将16位密钥记录在纸上，并保存在安全的地方如 遇手机丢失，你可以通过该密钥恢复你的谷歌验证
          </p>
        </div>
        <div class="d_flex justify_content_between download">
          <div class="text_light">{{ googleSecret }}</div>
          <a class="download_button_default" @click.stop.prevent="copyGoogleSecret">
            复制
          </a>
          <input type="hidden" id="googleSecret" :value="googleSecret" />
        </div>
        <router-link to="/my/securityCenter/googleAuthenticatorContent" class="text_primary">
          查看教程
        </router-link>
      </div>
    </template>

    <!-- step 3 -->
    <template v-if="index === '3'">
      <div class="w_100">
        <div class="d_flex">
          <div class="icon icon_medium">
            <img src="../../../assets/images/public/ic_security_secondary_verification.webp" alt="" />
          </div>
        </div>
        <div class="d_flex">
          <p>
            请存谷歌验证APP获取谷歌验证码
          </p>
        </div>
        <div class="input-captcha">
          <div class="text_left">
            谷歌二次验证
          </div>
          <div class="input-content">
            <v-otp-input ref="otpInput" separator="" :num-inputs="6" :should-auto-focus="true" input-type="password" @on-complete="handleOnComplete" />
          </div>
        </div>
        <router-link to="/my/securityCenter/googleAuthenticatorContent" class="text_primary">
          查看教程
        </router-link>
      </div>
    </template>

    <div class="btn_default btn_primary" @click="nextStep" v-show="index < 3">
      下一步
    </div>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container.vue";
import { Message } from "element-ui";
import PasswordInput from "../components/Vue-Otp-Input/OtpInput";
export default {
  components: {
    Container,
    "v-otp-input": PasswordInput,
  },
  data() {
    return {
      downloadUrl: "http://dgxdjx.com/admin/googleauthenticator/1605799574491.apk",
      titleContent: {
        1: {
          content: "下载并安装",
        },
        2: {
          content: "备份密钥",
        },
        3: {
          content: "二次验证",
        },
      },
      code: "",
    };
  },
  watch: {
    index: {
      handler: function(newVal) {
        this.changeHeader(this.titleContent[this.$route.query.step].content);
        if (newVal) {
          this.changeHeader(this.titleContent[newVal].content);
        }
      },
      immediate: true,
    },
    isGoogleSecret: {
      handler: function(newVal) {
        if (newVal === true) {
          this.$router.push("/my/securityCenter");
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("google", ["googleData"]),
    ...mapState("users", ["usersData"]),
    userMobile() {
      return this.usersData.userMobile ? this.usersData.userMobile : "";
    },
    isGoogleSecret() {
      return this.usersData.isGoogleSecret;
    },
    googleSecret() {
      return this.googleData.googleSecret ? this.googleData.googleSecret : "";
    },
    index() {
      return this.$route.query.step ? this.$route.query.step : 1;
    },
  },
  methods: {
    ...mapActions("google", ["openGoogleSecret"]),
    ...mapActions("header", ["changeHeader"]),
    ...mapActions("auth", ["googleCheckGoogleSecret"]),
    nextStep() {
      let index = +this.index;
      this.$router.push({
        query: { step: index + 1 },
      });
    },
    copydownloadUrl() {
      let downloadUrlToCopy = document.querySelector("#download-code");
      downloadUrlToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      downloadUrlToCopy.select();

      try {
        document.execCommand("copy");
        Message({
          message: "已复制",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } catch (err) {
        console.log("拷贝失败");
      }

      /* unselect the range */
      downloadUrlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copyGoogleSecret() {
      let downloadUrlToCopy = document.querySelector("#googleSecret");
      downloadUrlToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      downloadUrlToCopy.select();

      try {
        document.execCommand("copy");
        Message({
          message: "已复制",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } catch (err) {
        console.log("拷贝失败");
      }

      /* unselect the range */
      downloadUrlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    handleOnComplete(value) {
      this.code = value;
      this.postGoogleCheckGoogleSecret(this.code);
    },
    postGoogleCheckGoogleSecret(code) {
      let data = {
        code: code,
        googleSecret: this.googleSecret,
        userMobile: this.userMobile,
      };
      this.googleCheckGoogleSecret(data).then((res) => {
        if (res.code === "ok") {
          this.$router.push({
            path: "/my/securityCenter",
            replace: true,
          });
        }
        Message({
          message: res.msg,
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      });
    },
  },
  mounted() {
    this.openGoogleSecret();
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 1.5;
}

.text_light {
  color: var(--gray);
  text-decoration: none;
}

.text_primary {
  color: var(--orange);
  font-size: 0.9rem;
  text-decoration: none;
}

.text_left {
  text-align: left;
  transform: translateX(-95%);
  margin-top: 1.5rem;
  color: var(--gray);
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    position: absolute;
    bottom: 10%;
    left: 5%;
    right: 5%;
    max-width: 373px;
    margin: 0 auto;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }
}

.input-captcha {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 3rem;

  .input-content {
    padding: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
}

.icon {
  display: block;
  width: 2rem;
  transform: translateY(0.1rem);
  margin: 0 0.25rem;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &_medium {
    width: 3rem;
    transform: translateY(0rem);
  }
}

.w_100 {
  width: 100%;
}

.d_flex {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1.75rem 0;
  align-items: center;
}

.justify_content_between {
  justify-content: space-between;
}

.download {
  background-color: #ff12000d;
  padding: 0.75rem;
  text-decoration: none;
  color: var(--black);
  margin: 2.75rem 0;
  border-top: 1px solid #ff730017;
  border-bottom: 1px solid #ff730017;

  .btn_download {
    background-color: var(--orange);
    display: block;
  }
}

.download_button {
  background-color: var(--orange);
  padding: 0.25rem 0.75rem;
  color: white;
  text-decoration: none;
  display: block;
}

.download_button_default {
  padding: 0.25rem 0.75rem;
  color: var(--orange);
  text-decoration: none;
  display: block;
}
</style>
